/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Image, Subtitle, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Poradenství"} description={"Jedna ze tří hlavních služeb"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--left pb--25" style={{"paddingBottom":null}}>
              
              <Text className="swp-T00-mobile text-box fs--30 w--500 mt--02" style={{"maxWidth":897,"marginTop":null}} content={"<span style=\"background-color: rgb(126, 211, 33);\"><a href=\"/kontakt\"><font color=\"rgba(0,0,0,1)\">Nyní AKCE ! Poradenství do vyčerpání kapacity zdarma !!!<br></font></a></span>"}>
              </Text>

              <Text className="text-box fs--22 w--500" content={"<span style=\"background-color: rgb(223, 218, 218);\">Poptejte poradenství, v hodnotě 1.500 ,- Kč !</span>"}>
              </Text>

              <Text className="text-box fs--18 w--500" style={{"maxWidth":702}} content={"<b><font color=\"rgba(74,74,74,1)\"><b>Za tuto cenu dostanete konzultaci přímo ve Vaší zahradě!<br>Dojíždíme z Frýdlantu nad Ostravicí do vzdálenosti 10 km bezplatně, nad 10 km v ceně 10 Kč/km.<br>Konzultace v rozsahu zhruba 1 hodiny.<br>Poradíme s výběrem a umístěním stromků,<br>zkonzultujeme Vaše požadavky s pěstitelskými možnostmi,<br>najdeme nejlepší stanoviště pro citlivé rostliny,<br>odhadneme potenciál úrody, <br>doporučíme počty sazenic pro Vaše potřeby,<br>probereme základní ochranu a potřeby ovocných stromů.</b></font><br></b>"}>
              </Text>

              <Text className="text-box fs--20 w--500" content={"<font color=\"rgba(0,0,0,1)\"><b>Objednáním Poradenství získáváte kredit 500,- Kč na Výsadbu ovocných stromků (kredit platí po dobu 6 měsíců&nbsp; od realizace Poradenství).</b></font>"}>
              </Text>

              <Image className="--left" src={"https://cdn.swbpg.com/t/19015/aed4c74b96cc4fd3b3c838256e13f4f1_s=860x_.jpg"} sizes="100vw" style={{"maxWidth":2210}} srcSet={"https://cdn.swbpg.com/t/19015/aed4c74b96cc4fd3b3c838256e13f4f1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/19015/aed4c74b96cc4fd3b3c838256e13f4f1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/19015/aed4c74b96cc4fd3b3c838256e13f4f1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/19015/aed4c74b96cc4fd3b3c838256e13f4f1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/19015/aed4c74b96cc4fd3b3c838256e13f4f1_s=2000x_.jpg 2000w"}>
              </Image>

              <Subtitle className="subtitle-box mt--25" style={{"marginTop":null}} content={"<span style=\"color: rgb(142, 146, 172);\"><br></span>"}>
              </Subtitle>

              <Title className="title-box mt--06" style={{"maxWidth":620,"marginTop":null}} content={"<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4 pb--60 pt--60" style={{"backgroundColor":"var(--color-supplementary)"}} border={"4"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Výsadba, poradenství<br>&amp; řez ovocných stromků<br></span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Hlavní 68, Frýdlant nad Ostravicí</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+420 777 707 360<br>info@uzitecnazahrada.cz"}>
              </Text>

              <Text className="text-box" content={"IČ: 26787792<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}